@use 'styles/variables' as var;
@use 'styles/functions' as fn;

$heigthFree: calc(100vh - 72px);

.hero-section {
  position: relative;
  overflow: hidden;
  height: $heigthFree;
  display: flex;
  flex-direction: column;
  background: var.$color-blue-50;

  .hero-section-media {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .hero-section-info {
      height: $heigthFree;
      justify-content: center;
      width: 100vw;
      display: flex;
      flex-direction: column;
      color: #fff;
      padding: 64px fn.spacing(5);
      box-shadow: rgb(0 0 0 / 70%) 0 -150px 128px -100px inset;

      .hero-section-info-content {
        max-width: 320px;

        & > * {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
            margin-top: 32px;
          }
        }
      }
    }
  }
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: 1024px) {
  .hero-section {
    height: 528px;

    .hero-section-media {
      align-items: center;
      justify-content: flex-end;

      .hero-section-info {
        margin-right: 24px;
        min-width: 590px;
        max-width: 590px;
        padding: 0;
        box-shadow: none;

        .hero-section-info-content {
          max-width: 95%;

          .hero-section-text-content {
            max-width: 487px;
          }
        }
      }
    }
  }
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-xl) {
  .hero-section {
    align-items: center;

    .hero-section-media {
      min-width: 1440px;
      max-width: 1440px;
    }
  }
}
