@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/images' as img;

.play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
}

.main-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
}

.main-card-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main-card-image-container {
  position: relative;
  margin-bottom: 16px;
}

.article-card-image {
  width: 100%;
}

.main-card-image {
  border-radius: 8px;
}

.info-container .main-card-eyebrow,
.main-card-container .main-card-eyebrow {
  padding-bottom: 0;
  text-align: left;
  color: #3b6af7;
}

.releated-card-container {
  background: var.$color-blue-25;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  display: none;
}

.releated-card-item-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 0;
  border-top: 1px solid var.$color-border-primary;
}

.anchor-links,
.releated-card-item-container {
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &[data-active='true'] {
    text-decoration: underline;

    [data-testid='card-title'] {
      text-decoration: underline;
      color: #2c47f6;
      font-weight: 700;
    }

    h1[data-testid='card-title'] {
      text-decoration: underline;
      color: black;
    }
  }
}

.releated-card-item-image {
  position: relative;
  height: 70px;
}

.releated-card-list > :first-child .releated-card-item-container {
  border-top: 0;
}

.releated-card-list > :last-child {
  padding-bottom: 0;
}

.breadcrumb {
  padding-bottom: 0;
  color: var.$color-neutral-300;

  li:hover a,
  li:hover a:hover {
    color: #30f;
  }
}

.breadcrumb-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-section-desktop {
  display: none;
}

.hero-section-mobile {
  display: flex;
  width: 100vw;
}

.article-card-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100vw;
  max-width: 100vw;
}

.img-container {
  @include img.img-container;

  width: 100vw;

  div {
    height: 100% !important;
  }

  img {
    @include img.img-element;

    object-fit: cover;
    object-position: center center;
    border-radius: 0;
  }
}

.articles-carousel-items {
  padding-left: 0 !important;
}

.hero-section-mobile .articles-carousel-items:last-child {
  padding-right: 0;
}

.articles-carousel-wrapper {
  padding: 0;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}

.hero-search-input input {
  padding: 4px 16px;
}

.eyebrow-link {
  color: #3b6af7;
}

.flat-carousel-indicators {
  background-color: var.$color-neutral-300 !important;
  margin-top: fn.spacing(3);
  margin-bottom: fn.spacing(3);
}

@media screen and (min-width: 768px) {
  .hero-section-mobile {
    margin-left: -32px;
  }

  .info-container {
    padding: 0 32px;
  }
}

@media screen and (min-width: 1024px) {
  .main-section {
    margin-bottom: fn.spacing(6);
  }

  .releated-card-container {
    display: flex;
  }

  .hero-section-mobile {
    display: none;
  }

  .hero-section-desktop {
    display: flex;
  }
}
