@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0;
}

.subtitle {
  margin-bottom: fn.spacing(5);
}

.product-card-carousel {
  padding: 0;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: 768px) {
  .section {
    padding: fn.spacing(6) 32px;
  }
}
