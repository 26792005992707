@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.nav-articles-container {
  background-color: white;
  z-index: 2;
}

.nav-articles-container[data-sticky='true'] {
  position: sticky;
  top: 53px;
}

.nav-articles-section {
  text-align: center;
  padding: 16px 8px;
  background: linear-gradient(180.32deg, #e3ebfe 0.28%, #f9f9fe 127.95%);
  justify-content: center;
}

.nav-articles-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px 8px;
  border-bottom: 0.5px solid #10182817;
  background: var.$color-background-secondary;
  transition: all 0.5s ease-in;
  overflow: hidden;
}

.nav-articles-list[data-open='false'] {
  padding: 0;
  max-height: 0;
}

.nav-articles-item {
  border-radius: 8px;
  padding: 4px 12px;
  text-wrap: nowrap;
}

.nav-articles-item[data-active='true'] {
  background: var.$color-background-blue;
  color: #2c47f6;
}

.nav-articles-item-cotainer {
  display: flex;
  justify-content: center;
}

.nav-articles-title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
}

.chevron-icon {
  color: #3b6af7;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .nav-articles-list {
    flex-direction: row;
    gap: 32px;
    background: #fff;
    max-height: 100px;
  }

  .nav-articles-container [data-sticky='true'] {
    top: 63px;
  }

  .nav-articles-section {
    padding: 32px 16px;
  }
}
