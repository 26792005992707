@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.author-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.author-noimg {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #c0cbd8;
}

.card-link {
  color: #1d252f;
}

.author-link:hover,
.author-container a:hover {
  color: #2c47f6;
}

.author-title {
  color: #5c708a;
}
