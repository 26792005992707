@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.subnav-outer-container {
  position: relative;
  max-width: 100%;
}

@media screen and (min-width: 1024px) {
  .subnav-outer-container [class*='faq-section'] [class*='img-container'] {
    width: 390px;
    max-height: 160px;
  }
}

.result-section {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  height: 90vh;
  padding: fn.spacing(25) 0;
  gap: 8px;

  div {
    height: 125px;
    padding: 30px 0;
    display: contents;
  }
}

.info-btn button {
  padding-bottom: 8px;
}

.text-container {
  z-index: 1;
  position: relative;
  transform: translateY(90%);

  &[data-container-color='blue'] {
    background-color: #30f;
    padding: 24px;
    border-radius: var.$achieve-default-border-radius;
  }
}

.sub-title {
  margin-top: 16px;
  margin-bottom: 16px;
}

.loader-text {
  padding: 24px 24px 0;
  text-align: center;
}

.progress-container {
  div {
    display: block;
    width: 275px;
    height: 275px;
  }
}

.eyebrow {
  padding-bottom: 0;
  margin-bottom: 16px;
  color: var.$color-neutral-300;
}

.font-align-center {
  text-align: center !important;
}

.font-align-left {
  text-align: left !important;
}

.font-align-right {
  text-align: right !important;
}

.font-black {
  color: #1d252f !important;
}

.font-white {
  color: #fff !important;
}

.font-blue {
  color: #30f !important;
}

.font-gery {
  color: #30f !important;
}

.content-container {
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 750px;
}

.topic-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
  padding: 16px;
}

.chip-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
  padding: 0;
  text-align: center;

  button {
    padding: 0;
  }

  p {
    display: contents;
  }
}

.multiple-disclaimer {
  padding: 24px 0;
}

.close-title {
  display: flex;
  justify-content: end;
  padding: 16px 0;
}

.answers-list-multiple {
  padding: 0;
  padding-top: 16px;
  display: block;
  text-align: center;

  button {
    border-radius: fn.spacing(5);
    text-align: center;
    color: var.$color-neutral-900 !important;
    border-color: #c0cbd8 !important;
  }
}

.answers-list {
  padding: 0;

  p {
    width: 100%;
    text-align: left;
  }

  button {
    width: 100%;
    text-align: center;
    color: var.$color-neutral-900 !important;
    border-color: #c0cbd8 !important;
    padding: 16px !important;
  }
}

.selected-topic {
  color: #30f;
}

.topic-chip {
  span {
    color: var.$color-background-primary;
  }

  div {
    background-color: transparent !important;
  }

  img {
    height: auto;
    width: auto;
  }
}

.topic-chip[data-color='Red'] {
  padding: fn.spacing(2.5) fn.spacing(1.8);
  background: linear-gradient(89.95deg, #cd264e 0.05%, #e47b55 75.17%, #f9c95b 143.14%);
}

.topic-chip[data-color='Green'] {
  padding: fn.spacing(2.5) fn.spacing(1.8);
  background: linear-gradient(269.33deg, #f9c95b -39.77%, #a6c632 3.16%, #3fa63b 93.13%);
}

.topic-chip[data-color='Sky Blue'] {
  padding: fn.spacing(2.5) fn.spacing(1.8);
  background: linear-gradient(269.95deg, #3fa63b -81.69%, #21a1ff 93.64%);
}

.topic-chip[data-color='Blue'] {
  padding: fn.spacing(2.5) fn.spacing(1.8);
  background: linear-gradient(270.04deg, #30f 0.03%, #21a1ff 93.66%);
}

.icon-container {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.got-it-button {
  width: 100%;
  margin: 16px 0;
}

.continue-button {
  padding: 0;

  button {
    width: 100%;
  }
}

@media screen and (max-width: var.$breakpoint-min-lg) {
  .section[data-hero-variant='debt-consolidation'] {
    height: 342px !important;
  }
}
@media screen and (max-width: var.$breakpoint-min-md) {
  .section[data-hero-variant='debt-consolidation'] {
    height: 345px !important;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .loader-text {
    padding: 24px 0 0;
  }

  .text-container {
    transform: none;

    &[data-container-color='blue'] {
      padding: fn.spacing(5);
    }
  }
}

@media screen and (min-width: 768px) {
  .content-container {
    display: flex;
    min-height: 500px;
  }

  .result-section {
    min-height: fn.spacing(80);
  }

  .icon-container {
    width: 25%;
  }

  .text-container {
    transform: inherit;
    padding: 0;
    max-width: 640px;
  }

  .chip-container,
  .answers-list-multiple {
    padding: 0 fn.spacing(17);
  }

  .continue-button,
  .answers-list {
    padding: 0 fn.spacing(20);
  }
}

@media screen and (min-width: 1024px) {
  .continue-button,
  .answers-list {
    padding: 0 fn.spacing(35);
  }

  .text-container {
    transform: none;

    &[data-container-color='blue'] {
      padding: fn.spacing(5);
    }
  }
}
