@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.product-hero-container {
  padding: 0;

  &[data-narrow='true'] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-hero-container {
    padding: 32px var.$medium-and-up-gutter-width 64px;
  }
}
