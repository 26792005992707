@use 'styles/variables' as var;
@use 'styles/functions' as fn;

// Mobile styles, smaller than large breakpoint
.hero-section {
  margin: 0;
  padding: 32px 0;
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: 1024px) {
  .hero-section {
    margin: 0;
    padding: 64px 0;
  }
}
