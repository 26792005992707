@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.cards-title-eyebrow,
.cards-list-item-eyebrow {
  padding-bottom: 0;
}

.cards-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px 24px;
  border: 1px solid #c0cbd8;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0.5px 3px 0 #10182817;
}

.cards-list-item-image {
  width: 60px;
  height: 60px;
}

.app-container {
  margin-bottom: 16px;
}

.cards-list-item-icons {
  padding: fn.spacing(2.5);
  border: 1px solid var.$color-neutral-75;
  border-radius: 12px;
  margin-bottom: 8px;
}

.button {
  margin-bottom: 8px;
}

[data-variant='carousel'] .cards-list-item {
  min-width: 280px;
  max-width: 280px;
}

@media screen and (min-width: 768px) {
  [data-variant='carousel'] .cards-list-item {
    min-width: 380px;
    max-width: 380px;
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .cards-list-item {
    min-width: 380px;
    max-width: 380px;
  }
}
