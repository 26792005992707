@use 'styles/variables' as var;
@use 'styles/functions' as fn;

div .direct-mail-container {
  &.direct-mail-container {
    display: flex;
    align-items: center;
  }
}

.direct-mail-text {
  color: #fff;
  margin-right: 4px;
}

.direct-mail-link {
  color: #fff;

  &:hover {
    text-decoration: underline;
    text-underline-offset: fn.spacing(0.3);
  }
}
